import { useParams } from "@remix-run/react";
import posthogJs from "posthog-js";
import { useCallback, useMemo } from "react";
import { StudioEventTypes, StudioEventTypeToPayloadMap } from "sutro-analytics";
import { AnyObject } from "sutro-common/object-types";

/**
 * Extracts UTM tags from a URLSearchParams object.
 * @param params The URLSearchParams object to extract UTM tags from.
 * @returns An object containing the UTM tags.
 */

export const extractUtmTags = (
  params: URLSearchParams
): Record<string, string | undefined> => {
  const utmTags = {
    utm_source: params.get("utm_source") || undefined,
    utm_medium: params.get("utm_medium") || undefined,
    utm_campaign: params.get("utm_campaign") || undefined,
    utm_term: params.get("utm_term") || undefined,
    utm_content: params.get("utm_content") || undefined,
  };

  const result = Object.fromEntries(
    Object.entries(utmTags).filter(([_, value]) => value !== undefined)
  );

  return result;
};

export const useAnalytics = () => {
  const { id, dtId, edgeId, actionId, effectId } = useParams();

  const track = useCallback(
    <T extends StudioEventTypes>(
      event: T,
      properties?: T extends keyof StudioEventTypeToPayloadMap
        ? StudioEventTypeToPayloadMap[T]
        : AnyObject
    ) => {
      posthogJs.capture(event, {
        ...(id && { productId: id }),
        ...(dtId && { dtId }),
        ...(edgeId && { edgeId }),
        ...(actionId && { actionId }),
        ...(effectId && { effectId }),
        ...properties,
      });
    },
    [actionId, dtId, edgeId, effectId, id]
  );

  const pageview = useCallback(() => {
    posthogJs.capture("$pageview");
  }, []);

  return useMemo(() => ({ track, pageview }), [pageview, track]);
};
